<template>
  <div class="relative grid w-screen h-screen place-items-center bg-orange-to-green">
    <div class="relative z-10 max-w-xl p-6 mx-6 bg-white rounded lg:mx-0">
      <div v-if="!form.wasSuccessful">
        <h1 class="pb-6 text-brand-secondary">
          Welkom bij Mijn OPUS Pod
        </h1>
        <p class="text-brand-black">
          Log in door je e-mail in te voeren. We sturen je vervolgens een link in de mail waarmee je
          binnen 1
          klik
          in de Mijn OPUS Pod
          omgeving bent.
        </p>
        <form class="grid gap-6 my-6" @submit.prevent="form.post('/login')">
          <input id="email" v-model="form.email" placeholder="E-mailadres" type="email" name="email"
            class="w-full p-2 border border-gray-300 rounded" required>
          <div v-if="form.errors.email">
            {{ form.errors.email }}
          </div>
          <button type="submit" class="w-full button-primary" :disabled="form.processing">
            Verstuur
            mail
          </button>
        </form>
      </div>

      <div v-else-if="form.wasSuccessful">
        <h1 class="pb-6 text-brand-secondary">
          Welkom bij Mijn OPUS Pod
        </h1>
        <p class="text-brand-black">
          Als {{ form.email }} voorkomt in ons klantenbestand ontvang je binnen enkele minuten een
          e-mail
          met
          jouw unieke login-link.
        </p>
      </div>
    </div>
    <img src="@/images/background-logo.svg" alt="OPUS" class="absolute h-screen right-1/3 lg:right-1/2">
  </div>
</template>

<script setup lang="ts">
import { useForm } from '@inertiajs/vue3'
import { onBeforeUnmount } from 'vue'

const props = defineProps({
  hash: {
    type: String,
    default: ''
  },
  email: {
    type: String,
    default: ''
  }
});

const form = useForm({
  email: props.email,
});

const status = useForm({
  email: '',
  hash: '',
})

const interval = setInterval(() => {
  if (form.wasSuccessful) {
    if (status.hash !== props.hash) {
      status.email = form.email;
      status.hash = props.hash;
    }

    try {
      status.post('/login-status');
    } catch (error) { // Ignore error
      return;
    }
  }
}, 5000);

function clearLoginInterval() {
  clearInterval(interval);
}

onBeforeUnmount(clearLoginInterval);

</script>